//@flow
import {storeSessionStorage,removeLocalStorage} from './store-util'
const taskRootStateName = 'taskRoot'
const localStore = sessionStorage.getItem(taskRootStateName)

export const taskRootInitialState = localStore?JSON.parse(localStore): {
    [taskRootStateName]: {
        whichTaskRoot: 'work',
    }
}

type State = {
    +taskRoot: {
        +whichTaskRoot: string,
    }
}

type Action = {
    whichTaskRoot: string,
}

export const TypeSetTaskRoot = 'setTaskRoot'

export const taskRootActions = {
    [TypeSetTaskRoot]: (state:State, action:Action) => {
        const taskRootState = {
            [taskRootStateName]: {
                ...state[taskRootStateName],
                whichTaskRoot: action.whichTaskRoot
            }
        }
        removeLocalStorage(taskRootStateName)
        storeSessionStorage(taskRootStateName,taskRootState)
        return taskRootState
    },
}