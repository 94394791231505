//@flow
import * as crowdApis from '@lesofi/phoenix-js/lib/phoenixgoapi/crowd'
import * as genericApis from '@lesofi/phoenix-js/lib/phoenixgoapi/generic'
import { ActionUserLogin } from '../store/user'

export const getUserSession=(dispatch:any)=>{
    crowdApis.getWorkerSession().then(res=>{
        if (res.status === 200){
            res.json().then(data => {
                if (typeof data.error !== 'undefined'){
                    //dispatch({ type: "loginError", error: data.error })            
                }else {
                    dispatch({ type: ActionUserLogin, userInfo: data.user, jwtToken: data.token })            
                }
            })
        }
    }).catch(err=>{
        // To handle error here
    })
}

export const renewUserSession=(dispatch:any)=>{
    const apiPath = '/rest/crowd/gigsdata/renewSession'
    genericApis.getDataApi(apiPath).then(res =>{
        if (res.status === 200) {
            res.json().then(data => {
                if (typeof data.error !== 'undefined'){
                    //dispatch({ type: "loginError", error: data.error })            
                }else {
                    dispatch({ type: ActionUserLogin, userInfo: data.user, jwtToken: data.token })            
                }
            })
        }
    }).catch(err => {
        console.error(err)
    })
}