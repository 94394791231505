//@flow
import {storeSessionStorage,removeLocalStorage} from './store-util'
export const taskResumeStateName = 'taskResume'
const localStore = sessionStorage.getItem(taskResumeStateName)

export const taskResumeInitialState = localStore?JSON.parse(localStore):{
	taskResume: { 
		error: '', 
		loading: false,
		taskCancelled: false,
	}
}

type State = {
	+taskResume: {
		+error:string,
		+loading:boolean
	}
}

type Action = {
	error:string,
	loading: boolean
}

export const TypeCancelTaskRequest = 'cancelTaskRequest'
export const TypeCancelTaskError = "cancelTaskError"
export const TypeCancelTaskSuccess = "cancelTaskSuccess"

export const taskResumeActions = {
	cancelTaskRequest: (state:State)=>{
		const taskResumeState = {
			taskResume: {
				...state.taskResume,
				error: '',
				loading: true,
				taskCancelled: false
			}
		}

		removeLocalStorage(taskResumeStateName)
		storeSessionStorage(taskResumeStateName,taskResumeState)
		return taskResumeState
	},
	cancelTaskError: (state:State,action:Action)=>{
		const taskResumeState = {
			taskResume: {
				...state.taskResume,
				error: action.error,
				loading: false
			}
		}

		removeLocalStorage(taskResumeStateName)
		storeSessionStorage(taskResumeStateName,taskResumeState)
		return taskResumeState
	},
	cancelTaskSuccess: (state:State)=>{
		const taskResumeState = {
			taskResume: {
				...state.taskResume,
				error: '',
				loading: false,
				taskCancelled: true,
			}
		}

		removeLocalStorage(taskResumeStateName)
		storeSessionStorage(taskResumeStateName,taskResumeState)
		return taskResumeState
	}
}