//@flow
import * as React from 'react'
import { userInitialState, userActions } from './user'
import { tasksListInitialState, tasksListActions } from './tasksList'
import { taskAcceptInitialState, taskAcceptActions } from './taskAccept'
import { taskResumeInitialState, taskResumeActions } from './taskResume'
import { taskResultsetInitialState, taskResultsetActions } from './taskResultset'
import { taskReportInitialState,taskReportActions } from './taskReport'
import { taskResultInfoInitialState,taskResultInfoActions } from './taskResultInfo'
import { lookupInitialState,lookupActions } from './lookup'
import { appUserVerInitialState, appVerActions } from './appVersion'
import { genPageInitialState, genPageActions } from './genericPage'
import { taskRootInitialState, taskRootActions } from './taskRoot'
import { microTaskInitialState, microTaskActions } from './microTask'
import { wsGatewayInitialState,wsGatewayActions } from './wsgateway'
import { exceptionInitialState, exceptionActions } from './exception'
import { nextProcessRuleInitialState, nextProcessRulesActions} from './nextProcessRule'

const initialState= {
    ...userInitialState,
    ...tasksListInitialState,
    ...taskAcceptInitialState,
    ...taskResumeInitialState,
    ...taskResultsetInitialState,
    ...taskReportInitialState,
    ...taskResultInfoInitialState,
    ...lookupInitialState,
    ...appUserVerInitialState,
    ...genPageInitialState,
    ...taskRootInitialState,
    ...microTaskInitialState,
    ...wsGatewayInitialState,
    ...exceptionInitialState,
    ...nextProcessRuleInitialState,
};

const StoreContext: any = React.createContext(initialState)

const Actions = {
    ...userActions,
    ...tasksListActions,
    ...taskAcceptActions,
    ...taskResumeActions,
    ...taskResultsetActions,
    ...taskReportActions,
    ...taskResultInfoActions,
    ...lookupActions,
    ...appVerActions,
    ...genPageActions,
    ...taskRootActions,
    ...microTaskActions,
    ...wsGatewayActions,
    ...exceptionActions,
    ...nextProcessRulesActions,
};

const reducer = (state, action) => {
    const act = Actions[action.type];
    const update = act(state,action);
    return { ...state, ...update };
};

type Props = {
    children: React.Node,
}

export const StoreProvider = (props: Props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)
    return (
        <StoreContext.Provider value={{ state, dispatch}}>
            {props.children}
        </StoreContext.Provider>
    );
};

export const useStore = (store:any) => {
    const { state, dispatch } = React.useContext(StoreContext);
    return { state, dispatch }
}