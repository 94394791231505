//@flow
import {storeSessionStorage,removeLocalStorage} from './store-util'
const lookupStateName = 'lookup'
const localStore = sessionStorage.getItem(lookupStateName)

export const lookupInitialState = localStore?JSON.parse(localStore): {
    [lookupStateName]: {
        error: '',
        depositAccts: [],
        depositAcctsLoading: false,
        insuranceCos: [],
        insuranceCosLoading: false,
        demeterVendors: [],
        demeterVendorsLoading: false,
        demeterStoreLocations: [],
        demeterStoreLocationsLoading: false,
        vendors: [],
        vendorsLoading: false,
        generic: {}
	}
}

type State = {
    +lookup: {
        +error: string,
        +depositAccts: Array<any>,
        +depositAcctsLoading: boolean,
        +insuranceCos: Array<any>,
        +insuranceCosLoading: boolean,
        +demeterVendors: Array<any>,
        +demeterVendorsLoading: boolean,
        +demeterStoreLocations: Array<any>,
        +demeterStoreLocationsLoading: boolean,
        +vendors: Array<any>,
        +vendorsLoading: boolean,
        +generic: any
    } 
}

type Action = {
    error: string,
    depositAccts: Array<any>,
    insuranceCos: Array<any>,
    demeterVendors: Array<any>,
    demeterStoreLocations: Array<any>,
    vendors: Array<any>,
    genericLookupName: string,
    genericDatas: Array<any>
}

export const TypeLookupError = 'lookupError'
export const TypeLookupDepositAcctsFetch = 'lookupDepositAcctsFetch'
export const TypeLookupDepositAcctsReceived = 'lookupDepositAcctsReceived'
export const TypeLookupInsuranceCosFetch = 'lookupInsuranceCosFetch'
export const TypeLookupInsuranceCosReceived = 'lookupInsuranceCosReceived'
export const TypeLookupDemeterVendorsFetch = 'lookupDemeterVendorsFetch'
export const TypeLookupDemeterVendorsReceived = 'lookupDemeterVendorsReceived'
export const TypeLookupDemeterStoreLocationsFetch = 'lookupDemeterStoreLocationsFetch'
export const TypeLookupDemeterStoreLocationsReceived = 'lookupDemeterStoreLocationsReceived'
export const TypeLookupVendorsFetch = 'lookupVendorFetch'
export const TypeLookupVendorsReceived = 'lookupVendorsReceived'
export const TypeLookupGenericFetch = 'lookupGenericFetch'
export const TypeLookupGenericReceived = 'lookupGenericReceived'
export const TypeLookupGenericError = 'lookupGenericError'

export const lookupActions = {
    [TypeLookupError]: (state:State, action:Action) => {
        const lookupState = {
            lookup: {
                ...state.lookup,
                depositAcctsLoading: false,
                insuranceCosLoading: false,
                demeterVendorsLoading: false,
                demeterStoreLocationsLoading: false,
                vendorsLoading: false,
                error: action.error
            }
        }
        removeLocalStorage(lookupStateName)
        storeSessionStorage(lookupStateName,lookupState)
        return lookupState
    },
    [TypeLookupDepositAcctsFetch]: (state:State) => {
        const lookupState = {
            lookup: {
                ...state.lookup,
                depositAcctsLoading: true,
                error: ''
            }
        }
        removeLocalStorage(lookupStateName)
        storeSessionStorage(lookupStateName,lookupState)
		return lookupState
    },
    [TypeLookupDepositAcctsReceived]: (state:State,action:Action) => {
        const lookupState = {
            lookup: {
                ...state.lookup,
                depositAcctsLoading: false,
                depositAccts: action.depositAccts,
                error: ''
            }
        }
        
        removeLocalStorage(lookupStateName)
        storeSessionStorage(lookupStateName,lookupState)
        return lookupState
    },
    [TypeLookupInsuranceCosFetch]: (state:State) => {
        const lookupState = {
            lookup: {
                ...state.lookup,
                insuranceCosLoading: true,
                error: ''
            }
        }
       
        removeLocalStorage(lookupStateName)
        storeSessionStorage(lookupStateName,lookupState)
        return lookupState
    },
    [TypeLookupInsuranceCosReceived]: (state:State,action:Action) => {
        const lookupState = {
            lookup: {
                ...state.lookup,
                insuranceCosLoading: false,
                insuranceCos: action.insuranceCos,
                error: ''
            }
        }
        
        removeLocalStorage(lookupStateName)
        storeSessionStorage(lookupStateName,lookupState)
        return lookupState
    },
    [TypeLookupDemeterVendorsFetch]: (state:State) => {
        const lookupState = {
            lookup: {
                ...state.lookup,
                demeterVendorsLoading: true,
                error: ''
            }
        }
        
        removeLocalStorage(lookupStateName)
        storeSessionStorage(lookupStateName,lookupState)
        return lookupState
    },
    [TypeLookupDemeterVendorsReceived]: (state:State,action:Action) => {
        const lookupState = {
            lookup: {
                ...state.lookup,
                demeterVendorsLoading: false,
                demeterVendors: action.demeterVendors,
                error: ''
            }
        }
        removeLocalStorage(lookupStateName)
        storeSessionStorage(lookupStateName,lookupState)
        return lookupState
    },
    [TypeLookupDemeterStoreLocationsFetch]: (state:State) => {
        const lookupState = {
            lookup: {
                ...state.lookup,
                demeterStoreLocationsLoading: true,
                error: ''
            }
        }
        removeLocalStorage(lookupStateName)
        storeSessionStorage(lookupStateName,lookupState)
        return lookupState
    },
    [TypeLookupDemeterStoreLocationsReceived]: (state:State,action:Action) => {
        const lookupState = {
            lookup: {
                ...state.lookup,
                demeterStoreLocationsLoading: false,
                demeterStoreLocations: action.demeterStoreLocations,
                error: ''
            }
        }
        removeLocalStorage(lookupStateName)
        storeSessionStorage(lookupStateName,lookupState)
        return lookupState
    },
    [TypeLookupVendorsFetch]: (state:State) => {
        const lookupState = {
            lookup: {
                ...state.lookup,
                vendorsLoading: true,
                error: ''
            }
        }
        removeLocalStorage(lookupStateName)
        storeSessionStorage(lookupStateName,lookupState)
        return lookupState
    },
    [TypeLookupVendorsReceived]: (state:State,action:Action) => {
        const lookupState = {
            lookup: {
                ...state.lookup,
                vendorsLoading: false,
                vendors: action.vendors,
                error: ''
            }
        }
        removeLocalStorage(lookupStateName)
        storeSessionStorage(lookupStateName,lookupState)
        return lookupState
    },
    [TypeLookupGenericFetch]: (state:State,action:Action) => {
        const generic = {
            loading: true,
            datas: [],
            error: ''
        }

        const lookupState = {
            lookup: {
                ...state.lookup,
                [action.genericLookupName]: generic,
            }
        }
        removeLocalStorage(lookupStateName)
        storeSessionStorage(lookupStateName,lookupState)
        return lookupState
    },
    [TypeLookupGenericReceived]: (state:State,action:Action) => {
        const generic = {
            loading: false,
            datas: action.genericDatas,
            error: ''
        }
        const lookupState = {
            lookup: {
                ...state.lookup,
                [action.genericLookupName]: generic,
            }
        }
        removeLocalStorage(lookupStateName)
        storeSessionStorage(lookupStateName,lookupState)
        return lookupState
    },
    [TypeLookupGenericError]: (state:State,action:Action) => {
        const generic = {
            loading: false,
            datas: [],
            error: action.error
        }
        
        const lookupState = {
            lookup: {
                ...state.lookup,
                [action.genericLookupName]: generic,
            }
        }
        removeLocalStorage(lookupStateName)
        storeSessionStorage(lookupStateName,lookupState)
        return lookupState
    }
}