//@flow
import {storeSessionStorage,removeLocalStorage} from './store-util'
const taskReportStateName = 'taskReport'
const taskReportLocalStore = sessionStorage.getItem(taskReportStateName)

export const getStateName=()=> {
    return taskReportStateName
}

export const taskReportInitialState = taskReportLocalStore?JSON.parse(taskReportLocalStore):{
	[taskReportStateName]: { 
		error: '', 
        datas: [],
        workflowType:'',
        crowdGroupId:0,
        pageSize: 20,
		page: 1,
		pages: 0,
		sortField: 'yyyymmdd',
		sortOrder: 'desc',
		count: 0,
		search: '',
        loading: false,
        linkFrom: '',
        title: '',
        taskReportSelected: false
	}
}

type Action = {
    error: string,
    datas: Array<any>,
    workflowType:string,
    crowdGroupId:number,
    pageSize:number,
    page:number,
    pages:number,
    sortField:string,
    sortOrder:string,
    count:number,
    search:string,
    linkFrom:string,
    title:string,
}

type State = {
    +taskReport: {
        +error: string,
        +datas: Array<any>,
        +workflowType:string,
        +crowdGroupId:number,
        +pageSize:number,
        +page:number,
        +pages:number,
        +sortField:string,
        +sortOrder:string,
        +count:number,
        +search:string,
        +loading:boolean,
        +linkFrom:string,
        +title:string,
        +taskReportSelected:boolean
    }
}

export const ActionTaskReportSelected = 'taskReportSelected'
export const ActionTaskReportFetch = 'taskReportFetch'
export const ActionTaskReportReceived = 'taskReportReceived'
export const ActionTaskReportError = 'taskReportError'

export const taskReportActions = {
    [ActionTaskReportSelected]: (state:State,action:Action) => {
        const thisState = {
            [taskReportStateName]: {
                ...state[taskReportStateName],
                crowdGroupId: action.crowdGroupId,
                linkFrom: action.linkFrom,
                title: action.title,
                taskReportSelected: true,
                workflowType: action.workflowType
            }
        }
        
        removeLocalStorage(taskReportStateName)
        storeSessionStorage(taskReportStateName,thisState)
		return thisState
    },
    [ActionTaskReportFetch]: (state:State) => {
        const thisState = {
            [taskReportStateName]: {
                ...state[taskReportStateName],
                error: '',
                loading: true,
                datas: [],
                page: 1,
                pages: 0,
                sortField: 'yyyymmdd',
                sortOrder: 'desc',
                count: 0,
                taskReportSelected: false
            }
        }
        
        removeLocalStorage(taskReportStateName)
        storeSessionStorage(taskReportStateName,thisState)
		return thisState
    },
    [ActionTaskReportReceived]: (state:State,action:Action) => {
        const thisState = {
            [taskReportStateName]: {
                ...state[taskReportStateName],
                loading: false,
                datas: action.datas,
                workflowType: action.workflowType,
                crowdGroupId: action.crowdGroupId,
                pageSize: action.pageSize,
                page:action.page,
                pages:action.pages,
                sortField:action.sortField,
                sortOrder:action.sortOrder,
                count:action.count,
                search:action.search,
                taskReportSelected: false
            }
        }
        
        removeLocalStorage(taskReportStateName)
        storeSessionStorage(taskReportStateName,thisState)
		return thisState
    },
    [ActionTaskReportError]: (state:State,action:Action) => {
        const thisState = {
            [taskReportStateName]: {
                ...state[taskReportStateName],
                error: action.error,
                loading: false,
                datas: [],
                taskReportSelected: false
            }
        }
        
        removeLocalStorage(taskReportStateName)
        storeSessionStorage(taskReportStateName,thisState)
		return thisState
    }
}