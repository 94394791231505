//@flow
import {storeSessionStorage} from './store-util'

const nextProcessRuleStateName = 'nextProcessRule'
const sessionStore = sessionStorage.getItem(nextProcessRuleStateName)

export const getStateName=()=> {
    return nextProcessRuleStateName
}

export const nextProcessRuleInitialState = sessionStore?JSON.parse(sessionStore): {
    [nextProcessRuleStateName]: {
        error: '',
        loading: false,
        data: {}
   }
}

export type EvalField = {
    evalField: string,
    evalIsEmpty: boolean,
    evalIsNull: boolean,
    evalIntegerValue: number,
    evalStringValue: string,
}

export type NextProcessRule = {
    enabled: boolean,
    evalAllRules: boolean,
    evalEitherRules: boolean,
    nextWorkflowId: string,
    nextWorkflowRules: Array<EvalField>,
}

type State = {
    +nextProcessRule: {
        +error: string,
        +loading: boolean,
        +data: any,
    }
}

type Action = {
    error: string,
    data: any,
}

export const ActionNPRFetch = 'fetchNPRAction'
export const ActionNPRFetchError = 'fetchNPRErrorAction'
export const ActionNPRReceived = 'receivedNPRAction'

export const nextProcessRulesActions = {
    [ActionNPRFetch]: (state:State) => {
        const thisState = {
            [nextProcessRuleStateName]: {
                ...state[nextProcessRuleStateName],
                loading: true,
                error: ''
            }
        }
        storeSessionStorage(nextProcessRuleStateName,thisState)
        return thisState
    },
    [ActionNPRFetchError]: (state:State,action:Action) => {
        const thisState = {
            [nextProcessRuleStateName]: {
                ...state[nextProcessRuleStateName],
                loading: false,
                error: action.error
            }
        }
        storeSessionStorage(nextProcessRuleStateName,thisState)
        return thisState
    },
    [ActionNPRReceived]: (state:State,action:Action) => {
        const thisState = {
            [nextProcessRuleStateName]: {
                ...state[nextProcessRuleStateName],
                loading: false,
                error: '',
                data: action.data,
            }
        }
        storeSessionStorage(nextProcessRuleStateName,thisState)
        return thisState
    },
}