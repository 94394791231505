//@flow
import {storeSessionStorage} from './store-util'

const exceptionStateName = 'exception'
const sessionStore = sessionStorage.getItem(exceptionStateName)

export const getStateName=()=> {
    return exceptionStateName
}

export const exceptionInitialState = sessionStore?JSON.parse(sessionStore): {
    [exceptionStateName]: {
        error: '',
        fields: [],
   }
}

export type ExceptField = {
    uiField: string,
    label: string,
    value: string,
    excNote?: string
}

type State = {
    +exception: {
        +fields: Array<ExceptField>,
    } 
}

type Action = {
    field: ExceptField,
}

export const ActionClearException = 'clearExceptionAction'
export const ActionAddException = 'addExceptionAction'
export const ActionRemoveException = 'removeExceptionAction'

export const exceptionActions = {
    [ActionClearException]: (state:State) => {
        const thisTaskState = {
            [exceptionStateName]: {
                ...state[exceptionStateName],
                fields: [],
            }
        }

        storeSessionStorage(exceptionStateName,thisTaskState)
        return thisTaskState
    },
    [ActionAddException]: (state:State,action:Action) => {
        let fields = [...state[exceptionStateName].fields]
        fields.push(action.field)

        const thisTaskState = {
            [exceptionStateName]: {
                ...state[exceptionStateName],
                fields: fields,
            }
        }

        storeSessionStorage(exceptionStateName,thisTaskState)
        return thisTaskState
    },
    [ActionRemoveException]: (state:State,action:Action) => {
        let fields: Array<ExceptField> = [...state[exceptionStateName].fields]
      
        if (fields.length>0) {
            fields = fields.filter((f:ExceptField)=> f.uiField!==action.field.uiField)
        }
        
        const thisTaskState = {
            [exceptionStateName]: {
                ...state[exceptionStateName],
                fields: fields,
            }
        }
        storeSessionStorage(exceptionStateName,thisTaskState)
        return thisTaskState
    },
}