//@flow
const microTaskStateName = 'microTask'
const sessionStore = sessionStorage.getItem(microTaskStateName)

export const getStateName=()=> {
    return microTaskStateName
}

export const microTaskInitialState = sessionStore?JSON.parse(sessionStore): {
    [microTaskStateName]: {
        error: '',
        requesting: false,
        listening: false,
        taskAcceptedInfo: null,
        taskEvent: null
   }
}

type State = {
    +microTask: {
        +error: string,
        +requesting: boolean,
        +listening: boolean, 
        +taskAcceptedInfo: any,
        +taskEvent: any
    } 
}

type Action = {
    error: string,
    taskAcceptedInfo: any,
    taskEvent: any
}

export const TypeMicroTaskRequestFetch = 'microTaskRequestFetch'
export const TypeMicroTaskRequestError = 'microTaskRequestError'
export const TypeMicroTaskRequestReceived = 'microTaskRequestReceived'
export const TypeMicroTaskListenFetch = 'microTaskListenFetch'
export const TypeMicroTaskListenError = 'microTaskListenError'
export const TypeMicroTaskListenReceived = 'microTaskListenReceived'
export const TypeMicroTaskClearState = 'microTaskClearState'

const storeState = (microTaskState:any)=>{
    try{
        sessionStorage.setItem(microTaskStateName,JSON.stringify(microTaskState))
    }catch(e){
        console.log('microTaskActions:storeState: ',e)
    }
}

export const microTaskActions = {
    [TypeMicroTaskClearState]: (state:State) => {
        const thisTaskState = {
            [microTaskStateName]: {
                ...state[microTaskStateName],
                requesting: false,
                listening: false,
                error: '',
                taskAcceptedInfo: null,
                taskEvent: null
            }
        }

        storeState(thisTaskState)
        return thisTaskState
    },
    [TypeMicroTaskRequestFetch]: (state:State) => {
        const thisTaskState = {
            [microTaskStateName]: {
                ...state[microTaskStateName],
                requesting: true,
                error: '',
                taskAcceptedInfo: null,
                taskEvent: null
            }
        }

        storeState(thisTaskState)
        return thisTaskState
    },
    [TypeMicroTaskRequestError]: (state:State,action:Action) => {
        const thisTaskState = {
            [microTaskStateName]: {
                ...state[microTaskStateName],
                requesting: false,
                error: action.error,
            }
        }
        storeState(thisTaskState)
        return thisTaskState
    },
    [TypeMicroTaskRequestReceived]: (state:State,action:Action) => {
        const thisTaskState = {
            [microTaskStateName]: {
                ...state[microTaskStateName],
                requesting: false,
                error: '',
                taskEvent: action.taskEvent
            }
        }
        storeState(thisTaskState)
        return thisTaskState    
    },
    [TypeMicroTaskListenFetch]: (state:State) => {
        const thisTaskState = {
            [microTaskStateName]: {
                ...state[microTaskStateName],
                listening: true,
                error: '',
            }
        }

        storeState(thisTaskState)
        return thisTaskState
    },
    [TypeMicroTaskListenError]: (state:State,action:Action) => {
        const thisTaskState = {
            [microTaskStateName]: {
                ...state[microTaskStateName],
                listening: false,
                error: action.error,
            }
        }
        storeState(thisTaskState)
        return thisTaskState        
    },
    [TypeMicroTaskListenReceived]: (state:State,action:Action) => {
        const thisTaskState = {
            [microTaskStateName]: {
                ...state[microTaskStateName],
                listening: false,
                error: '',
                taskAcceptedInfo: action.taskAcceptedInfo
            }
        }
        
        storeState(thisTaskState)
        return thisTaskState
    }
}