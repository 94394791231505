//@flow
import React,{Suspense,lazy,useEffect,useCallback,useState} from 'react';
import { Switch, Route, BrowserRouter as Router,Redirect  } from 'react-router-dom'
import AppHeader from './components/top-level/AppHeader'
import AppFooter from './components/top-level/AppFooter'
import { useStore } from './store/useStore'
import Spinner from './components/top-level/Spinner'
import Analytics from 'react-router-ga'
import { getUserSession,renewUserSession } from './services/user-util'
import { refreshAppUserVersion, getAppVersion } from './services/useAppVersion'

const Login = lazy(()=> import('./components/user/Login'))
const Profile = lazy(()=> import('./components/user/Profile'))
const Work = lazy(()=> import('./components/containers/Work'))
const Learn = lazy(()=> import('./components/containers/Learn'))
const Qualify = lazy(()=> import('./components/containers/Qualify'))
const NotFound = lazy(()=> import('./components/other/NotFound'))
const TaskAccept = lazy(()=> import('./components/task-workflow/TaskAccept'))
const TaskResume = lazy(()=> import('./components/task-workflow/TaskResume'))
const ViewInstruction = lazy(()=> import('./components/data-extraction/ViewInstruction'))
const ViewDocument = lazy(()=> import('./components/data-extraction/ViewDocument'))
const ShareViewDocument = lazy(()=> import('./components/data-extraction/ShareViewDocument'))
const Rhea = lazy(()=> import('./components/data-extraction/Rhea'))
const Ares = lazy(()=> import('./components/data-extraction/Ares'))
const Apollo = lazy(()=> import('./components/data-extraction/Apollo'))
const Apollo2 = lazy(()=> import('./components/data-extraction/Apollo2'))
const Jason = lazy(() => import('./components/data-extraction/Jason'))
const Theia = lazy(()=> import('./components/data-extraction/Theia'))
const Zeus = lazy(()=> import('./components/data-extraction/Zeus'))
const Kratos = lazy(()=> import('./components/data-extraction/Kratos'))
const ViewStreetNamesList = lazy(()=> import('./components/data-extraction/Kratos/ViewStreetNamesList'))
const SubmittedAck = lazy(()=> import('./components/data-extraction/SubmittedAck'))
const TaskReport = lazy(()=> import('./components/task-workflow/TaskReport'))
const AppUpdate = lazy(()=> import('./components/other/AppUpdate'))
const Selene = lazy(()=> import('./components/data-extraction/Selene'))
const MorpheusInvoice = lazy(()=> import('./components/data-extraction/MorpheusInvoice'))
const MorpheusQuote = lazy(()=> import('./components/data-extraction/MorpheusQuote'))
const Athena = lazy(()=> import('./components/data-extraction/Athena'))
const ViewNPIRegistry = lazy(()=> import('./components/data-extraction/Athena/ViewNPIRegistry'))
const IO = lazy(()=> import('./components/data-extraction/IO'))
const Chronos = lazy(()=> import('./components/data-extraction/Chronos'))
const Nemesis = lazy(()=> import('./components/data-extraction/Nemesis'))
const Aether = lazy(()=> import('./components/data-extraction/Aether'))
const Nesoi = lazy(()=> import('./components/data-extraction/Nesoi'))
const Gelos = lazy(()=> import('./components/data-extraction/Gelos'))
const Eos = lazy(()=> import('./components/data-extraction/Eos'))
const CriusInvoice = lazy(()=> import('./components/data-extraction/CriusInvoice'))
const CriusStmt = lazy(()=> import('./components/data-extraction/CriusStmt'))
const Pallas = lazy(()=> import('./components/data-extraction/Pallas'))
const Hera = lazy(()=> import('./components/data-extraction/Hera'))
const Grey = lazy(()=> import('./components/data-extraction/Grey'))
const Diana = lazy(()=> import('./components/data-extraction/Diana'))
const ImgAnnot = lazy(()=> import('./components/image-annotation/ImgAnnot'))
const Hemera = lazy(()=> import('./components/data-extraction/Hemera'))
const MicroTaskAccept = lazy(()=> import('./components/task-workflow/MicroTaskAccept'))
const NyxSumm = lazy(()=> import('./components/data-extraction/NyxSumm'))
const NyxLines = lazy(()=> import('./components/data-extraction/NyxLines'))
const Cura = lazy(()=> import('./components/data-extraction/Cura'))
const Flora = lazy(()=> import('./components/data-extraction/Flora'))
const Flora2 = lazy(()=> import('./components/data-extraction/Flora2'))
const Proteus = lazy(()=> import('./components/data-extraction/Proteus'))
const Pontus = lazy(()=> import('./components/data-extraction/Pontus'))
const Zephyrus = lazy(()=> import('./components/data-extraction/Zephyrus'))
const Boreas = lazy(()=> import('./components/data-extraction/Boreas'))
const Notus = lazy(()=> import('./components/data-extraction/Notus'))
const CloneFlora = lazy(()=> import('./components/data-extraction/CloneFlora'))

const PrivateRoute = ({component: Component, authed, ...rest}) => {  
  return (    
       <Route {...rest} render={(props) => authed === true 
         ? <Component {...props} /> 
         : <Redirect to={{pathname: '/login', state: {from: props.location }}} />} />  
  )
}

const ChildComponent = (appVer:any, component: any) => {
  if (appVer.appUpdate) {
   return AppUpdate
  }
  return component
}

const TaskRootComponent = (taskRootState:any) => {
  switch(taskRootState.whichTaskRoot){
    case 'learn':
      return Learn
    case 'qualify':
      return Qualify
    default:  
  }

  return Work
}


function App() {
  const { dispatch, state } = useStore()
  const appVer = state.appVer 
  const taskRootState = state.taskRoot
  const appVerAppUpdate = state.appVer && state.appVer.appUpdate 
  const loggedIn = state.user.loggedIn
  const userInfo = state.user && state.user.userInfo  
  const [timeInterval,setTimeInterval] = useState()
  
  const callRenewSession = useCallback(()=>{
    if (loggedIn && userInfo){
      renewUserSession(dispatch)
    }
  },[dispatch,userInfo,loggedIn]) 

  useEffect(()=>{
    getUserSession(dispatch)
  },[dispatch])
 
  useEffect(()=> {
    if (loggedIn){
      getAppVersion(dispatch)
      refreshAppUserVersion()
    }
  },[dispatch,loggedIn])

  useEffect(()=>{
    if (!timeInterval && loggedIn){
      const duration = 3600000 // 60 minutes / 1 hour 
      const newTimerInterval = setInterval(callRenewSession,duration)
      setTimeInterval(newTimerInterval)
    }
  },[callRenewSession,loggedIn,timeInterval])

  useEffect(()=>{
    return ()=>{
      if (loggedIn && timeInterval) {
        clearInterval(timeInterval)
      }
    }
  },[loggedIn,timeInterval])

  return (
    <Router>
      <React.Fragment>
        <AppHeader />
        <main>
          <Analytics id="UA-93381200-7">
            <Suspense fallback={<Spinner />}> 
              <Switch>
                <Route path="/login" exact={true} component={Login} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path="/dataExtraction/submittedAck" component={SubmittedAck} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/profile' component = {ChildComponent(appVer,Profile)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/learn' component = {ChildComponent(appVer,Learn)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/qualify' component = {ChildComponent(appVer,Qualify)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/work' component = {ChildComponent(appVer,Work)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/taskAccept' component = {ChildComponent(appVer,TaskAccept)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/microTaskAccept' component = {ChildComponent(appVer,MicroTaskAccept)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/taskResume' component = {ChildComponent(appVer,TaskResume)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/taskReport' component = {ChildComponent(appVer,TaskReport)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/instruction' component = {ChildComponent(appVer,ViewInstruction)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/document' component = {ChildComponent(appVer,ViewDocument)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/rhea' component = {appVerAppUpdate?AppUpdate:Rhea} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/ares' component = {ChildComponent(appVer,Ares)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/ares/v2/:version' component = {ChildComponent(appVer,Ares)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/apollo' component = {ChildComponent(appVer,Apollo)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/apollo2' component = {ChildComponent(appVer,Apollo2)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/jason' component = {ChildComponent(appVer,Jason)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/theia' component = {appVerAppUpdate?AppUpdate:Theia} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/zeus' component = {ChildComponent(appVer,Zeus)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/selene' component = {ChildComponent(appVer,Selene)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/morpheusInvoice' component = {appVerAppUpdate?AppUpdate:MorpheusInvoice} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/morpheusQuote' component = {appVerAppUpdate?AppUpdate:MorpheusQuote} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/athena' component = {ChildComponent(appVer,Athena)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/athena/v2/:version' component = {ChildComponent(appVer,Athena)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/athena/npiRegistry' component = {appVerAppUpdate?AppUpdate:ViewNPIRegistry} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/kratos' component = {ChildComponent(appVer,Kratos)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/kratos/streetNames' component = {ChildComponent(appVer,ViewStreetNamesList)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/io' component = {ChildComponent(appVer,IO)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/chronos' component = {appVerAppUpdate?AppUpdate:Chronos} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/nemesis' component = {appVerAppUpdate?AppUpdate:Nemesis} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/aether' component = {ChildComponent(appVer,Aether)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/nesoi' component = {appVerAppUpdate?AppUpdate:Nesoi} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/gelos' component = {appVerAppUpdate?AppUpdate:Gelos} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/eos' component = {appVerAppUpdate?AppUpdate:Eos} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/pallas' component = {ChildComponent(appVer,Pallas)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/criusInvoice' component = {ChildComponent(appVer,CriusInvoice)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/criusInvoice/:version' component = {ChildComponent(appVer,CriusInvoice)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/criusStmt' component = {ChildComponent(appVer,CriusStmt)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/hera' component = {ChildComponent(appVer,Hera)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/hemera' component = {ChildComponent(appVer,Hemera)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/grey' component = {ChildComponent(appVer,Grey)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/diana' component = {ChildComponent(appVer,Diana)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/nyxSummary' component = {ChildComponent(appVer,NyxSumm)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/nyxLines' component = {ChildComponent(appVer,NyxLines)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/imageAnnotation/imgAnnot' component = {ChildComponent(appVer,ImgAnnot)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/cura' component = {ChildComponent(appVer,Cura)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/flora' component = {ChildComponent(appVer,Flora)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/flora/:version' component = {ChildComponent(appVer,Flora)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/flora2' component = {ChildComponent(appVer,Flora2)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/flora2/:version' component = {ChildComponent(appVer,Flora2)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/zephyrus' component = {ChildComponent(appVer,Zephyrus)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/zephyrus/:version' component = {ChildComponent(appVer,Zephyrus)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/boreas' component = {ChildComponent(appVer,Boreas)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/boreas/:version' component = {ChildComponent(appVer,Boreas)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/notus' component = {ChildComponent(appVer,Notus)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/notus/:version' component = {ChildComponent(appVer,Notus)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/cloneFlora/:workflowType' component = {ChildComponent(appVer,CloneFlora)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/cloneFlora/:workflowType/:version' component = {ChildComponent(appVer,CloneFlora)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/proteus' component = {ChildComponent(appVer,Proteus)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/pontus' component = {ChildComponent(appVer,Pontus)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/dataExtraction/share/document/:workflowType/:accessKey' component = {ChildComponent(appVer,ShareViewDocument)} />
                <PrivateRoute authed={state.user.loggedIn} exact={true} path='/' component = {ChildComponent(appVer,TaskRootComponent(taskRootState))} />
                <Route >
                  <NotFound />
                </Route>
              </Switch>
            </Suspense>
          </Analytics>
        </main>
        <AppFooter />
      </React.Fragment>
    </Router>
  );
}

export default App;
