//@flow
import { removeUnusedAttributesInObject,removeUnusedAttributesInObjectArray,storeLocalStorage} from './store-util'

const taskResultsetStateName = 'taskResultset'
const localStore = localStorage.getItem(taskResultsetStateName)

export const taskResultsetInitialState = localStore?JSON.parse(localStore): {
    taskResultset: {
        error: '',
        loading: false,
        resultset: null,
		isInvalidDoc: false,
		vendors: [],
		vendorsLoading: false,
		rheaRequestors: [],
		rheaRequestorsLoading: false,
		submitted: false,
		apolloVendorDocTempLines: [],
		apolloVendorDocTempLinesLoading: false,
		apolloVendorServiceAddresses: [],
		apolloVendorServiceAddressesLoading: false,
		lineItems: [],
		lineItemsLoading: false,
		lineItemsPageSize: 5,
		lineItemsPage: 1,
		lineItemsPages: 0,
		lineItemsSortField: 'item_row_index',
		lineItemsSortOrder: 'asc',
		lineItemsCount: 0,
		lineItemsSearch: '',
		lineItemUpdated: false,
		lineItemSumFieldValue: 0
    }
}

type State = {
    +taskResultset: {
        +error: string,
        +loading: boolean,
        +resultset: any,
		+isInvalidDoc: boolean,
		+vendors: Array<any>,
		+vendorsLoading: boolean,
		+rheaRequestors: Array<any>,
		+rheaRequestorsLoading: boolean,
		+submitted: boolean,
		+apolloVendorDocTempLines: Array<any>,
		+apolloVendorDocTempLinesLoading: boolean,
		+apolloVendorServiceAddresses: Array<any>,
		+apolloVendorServiceAddressesLoading: boolean,
		+lineItems: Array<any>,
		+lineItemsLoading: boolean,
		+lineItemsPageSize: number,
		+lineItemsPage: number,
		+lineItemsPages: number,
		+lineItemsSortField: string,
		+lineItemsSortOrder: string,
		+lineItemsCount: number,
		+lineItemsSearch: string,
		+lineItemUpdated: boolean,
		+lineItemSumFieldValue: number
    }
}

type Action = {
    error: string,
    loading: boolean,
    resultset: any,
	isInvalidDoc: boolean,
	vendors: Array<any>,
	rheaRequestors: Array<any>,
	apolloVendorDocTempLines: Array<any>,
	apolloVendorServiceAddresses: Array<any>,
	lineItems: Array<any>,
	lineItemsPageSize: number,
	lineItemsPage: number,
	lineItemsPages: number,
	lineItemsSortField: string,
	lineItemsSortOrder: string,
	lineItemsCount: number,
	lineItemsSearch: string,
	lineItemSumFieldValue: number
}

export const TypeTaskResultsetFetch = 'taskResultsetFetch'
export const TypeTaskResultsetError = 'taskResultsetError'
export const TypeTaskResultsetReceived = 'taskResultsetReceived'
export const TypeTaskResultsetInvalidDocTrigger = 'taskResultsetInvalidDocTrigger'
export const TypeTaskResultsetVendorsFetch = 'taskResultsetVendorsFetch'
export const TypeTaskResultsetVendorsReceived = 'taskResultsetVendorsReceived'
export const TypeTaskResultsetRheaRequestorsFetch = 'taskResultsetRheaRequestorsFetch'
export const TypeTaskResultsetRheaRequestorsReceived = 'taskResultsetRheaRequestorsReceived'
export const TypeTaskResultsetApolloVendorDocTempLinesFetch = 'taskResultsetApolloVendorDocTempLinesFetch'
export const TypeTaskResultsetApolloVendorDocTempLinesReceived = 'taskResultsetApolloVendorDocTempLinesReceived'
export const TypeTaskResultsetApolloVendorServiceAddressesFetch = 'taskResultsetApolloVendorServiceAddressesFetch'
export const TypeTaskResultsetApolloVendorServiceAddressesReceived = 'taskResultsetApolloVendorServiceAddressesReceived'
export const TypeTaskResultsetLineItemsFetch = 'taskResultsetLineItemsFetch'
export const TypeTaskResultsetLineItemsReceived = 'taskResultsetLineItemsReceived'
export const TypeTaskResultsetLineItemUpdated = 'taskResultsetLineItemUpdated'
export const TypeTaskResultsetSubmitted = 'taskResultsetSubmitted'
export const TypeTaskResultsetClear = 'taskResultsetClear'

const unusedResultsetAttrs = ['file_drop_id','created_at','xml_count','xml_returned_at','updated_at']
const unusedVendorAttrs = ['created_at','is_recur','updated_at']
const unusedApolloVendorDocTmpLineAttrs = ['created_at','updated_at']

export const taskResultsetActions = {
	[TypeTaskResultsetFetch]: (state:State)=> {
		const taskResultsetState = {
			taskResultset: {
				...state.taskResultset,
				loading: true,
				submitted: false,
				error: '',
			}
		}
		//removeLocalStorage(taskResultsetStateName)
		storeLocalStorage(taskResultsetStateName,taskResultsetState)
		return taskResultsetState
	},
	[TypeTaskResultsetError]: (state:State, action:Action) => {
		const taskResultsetState = {
			taskResultset: {
				...state.taskResultset,
				loading: false,
				error: action.error
			}
		}
		//removeLocalStorage(taskResultsetStateName)
		storeLocalStorage(taskResultsetStateName,taskResultsetState)
		return taskResultsetState
	},
	[TypeTaskResultsetReceived]: (state:State, action:Action) => {
		let resultset = action.resultset
		if (resultset) {
			resultset = removeUnusedAttributesInObject(resultset,unusedResultsetAttrs)
		}
		const taskResultsetState = {
			taskResultset: {
				...state.taskResultset,
				loading: false,
				resultset: resultset,
				error: ''   
			}
		}
		//removeLocalStorage(taskResultsetStateName)
		storeLocalStorage(taskResultsetStateName,taskResultsetState)
		return taskResultsetState
	},
	[TypeTaskResultsetInvalidDocTrigger]: (state:State, action:Action) => {
		const taskResultsetState = {
			taskResultset: {
				...state.taskResultset,
				isInvalidDoc: action.isInvalidDoc
			}
		}
		//removeLocalStorage(taskResultsetStateName)
		storeLocalStorage(taskResultsetStateName,taskResultsetState)
		return taskResultsetState	
	},
	[TypeTaskResultsetVendorsFetch]: (state:State) => {
		const taskResultsetState = {
			taskResultset: {
				...state.taskResultset,
				vendorsLoading: true,
			}
		}
		//removeLocalStorage(taskResultsetStateName)
		storeLocalStorage(taskResultsetStateName,taskResultsetState)
		return taskResultsetState	
	},
	[TypeTaskResultsetVendorsReceived]: (state:State, action:Action) => {
		let vendors = action.vendors
		if (vendors) {
			vendors = removeUnusedAttributesInObjectArray(vendors,unusedVendorAttrs)
		}
		const taskResultsetState = {
			taskResultset: {
				...state.taskResultset,
				vendors: vendors,
				vendorsLoading: false,
			}
		}
		//removeLocalStorage(taskResultsetStateName)
		storeLocalStorage(taskResultsetStateName,taskResultsetState)
		return taskResultsetState	
	},
	[TypeTaskResultsetRheaRequestorsFetch]: (state:State) => {
		const taskResultsetState = {
			taskResultset: {
				...state.taskResultset,
				rheaRequestorsLoading: true,
			}
		}
		//removeLocalStorage(taskResultsetStateName)
		storeLocalStorage(taskResultsetStateName,taskResultsetState)
		return taskResultsetState	
	},
	[TypeTaskResultsetRheaRequestorsReceived]: (state:State, action:Action) => {
		const taskResultsetState = {
			taskResultset: {
				...state.taskResultset,
				rheaRequestorsLoading: false,
				rheaRequestors: action.rheaRequestors
			}
		}
		//removeLocalStorage(taskResultsetStateName)
		storeLocalStorage(taskResultsetStateName,taskResultsetState)
		return taskResultsetState	
	},
	[TypeTaskResultsetApolloVendorDocTempLinesFetch]: (state:State) => {
		const taskResultsetState = {
			taskResultset: {
				...state.taskResultset,
				apolloVendorDocTempLinesLoading: true,
			}
		}
		//removeLocalStorage(taskResultsetStateName)
		storeLocalStorage(taskResultsetStateName,taskResultsetState)
		return taskResultsetState
	},
	[TypeTaskResultsetApolloVendorDocTempLinesReceived]: (state:State, action:Action) => {
		let apolloVendorDocTempLines = action.apolloVendorDocTempLines
		if (apolloVendorDocTempLines) {
			apolloVendorDocTempLines = removeUnusedAttributesInObjectArray(apolloVendorDocTempLines,unusedApolloVendorDocTmpLineAttrs)
		}
		const taskResultsetState = {
			taskResultset: {
				...state.taskResultset,
				apolloVendorDocTempLinesLoading: false,
				apolloVendorDocTempLines: apolloVendorDocTempLines,
			}
		}
		//removeLocalStorage(taskResultsetStateName)
		storeLocalStorage(taskResultsetStateName,taskResultsetState)
		return taskResultsetState	
	},
	[TypeTaskResultsetApolloVendorServiceAddressesFetch]: (state:State) => {
		const taskResultsetState = {
			taskResultset: {
				...state.taskResultset,
				apolloVendorServiceAddressesLoading: true,
			}
		}
		
		//removeLocalStorage(taskResultsetStateName)
		storeLocalStorage(taskResultsetStateName,taskResultsetState)
		return taskResultsetState
	},
	[TypeTaskResultsetApolloVendorServiceAddressesReceived]: (state:State,action:Action) => {
		const taskResultsetState = {
			taskResultset: {
				...state.taskResultset,
				apolloVendorServiceAddressesLoading: false,
				apolloVendorServiceAddresses: action.apolloVendorServiceAddresses,
			}
		}
		//removeLocalStorage(taskResultsetStateName)
		storeLocalStorage(taskResultsetStateName,taskResultsetState)
		return taskResultsetState
	},
	[TypeTaskResultsetLineItemsFetch]: (state:State) => {
		const taskResultsetState = {
			taskResultset: {
				...state.taskResultset,
				lineItemsLoading: true,
			}
		}
		//removeLocalStorage(taskResultsetStateName)
		storeLocalStorage(taskResultsetStateName,taskResultsetState)
		return taskResultsetState
	},
	[TypeTaskResultsetLineItemsReceived]: (state:State, action:Action) => {
		const taskResultsetState = {
			taskResultset: {
				...state.taskResultset,
				lineItemsLoading: false,
				lineItemUpdated: false,
				lineItems: action.lineItems,
				lineItemsPageSize: action.lineItemsPageSize,
				lineItemsPage: action.lineItemsPage,
				lineItemsPages: action.lineItemsPages,
				lineItemsSortField: action.lineItemsSortField,
				lineItemsSortOrder: action.lineItemsSortOrder,
				lineItemsCount: action.lineItemsCount,
				lineItemsSearch: action.lineItemsSearch,
				lineItemSumFieldValue: action.lineItemSumFieldValue,
			}
		}
		//removeLocalStorage(taskResultsetStateName)
		storeLocalStorage(taskResultsetStateName,taskResultsetState)
		return taskResultsetState	
	},
	[TypeTaskResultsetLineItemUpdated]: (state:State) => {
		const taskResultsetState = {
			taskResultset: {
				...state.taskResultset,
				lineItemUpdated: true
			}
		}
		//removeLocalStorage(taskResultsetStateName)
		storeLocalStorage(taskResultsetStateName,taskResultsetState)
		return taskResultsetState
	},
	[TypeTaskResultsetSubmitted]: (state:State)=> {
		const taskResultsetState = {
			taskResultset: {
				...state.taskResultset,
				submitted: true
			}
		}
		//removeLocalStorage(taskResultsetStateName)
		storeLocalStorage(taskResultsetStateName,taskResultsetState)
		return taskResultsetState
	},
	[TypeTaskResultsetClear]: (state:State) => {
		const taskResultsetState = {
			taskResultset: {
				...state.taskResultset,
				loading: false,
				resultset: null,
				isInvalidDoc: false,
				vendors: [],
				vendorsLoading: false,
				rheaRequestors: [],
				rheaRequestorsLoading: false,
				apolloVendorDocTempLines: [],
				apolloVendorDocTempLinesLoading: false,
				apolloVendorServiceAddresses: [],
				apolloVendorServiceAddressesLoading: false,
				lineItemsLoading: false,
				lineItems: [],
				lineItemsPageSize: 5,
				lineItemsPage: 1,
				lineItemsPages: 0,
				lineItemsSortField: 'item_row_index',
				lineItemsSortOrder: 'asc',
				lineItemsCount: 0,
				lineItemsSearch: '',
				lineItemUpdated: false,
				submitted: false,
			}
		}
		//removeLocalStorage(taskResultsetStateName)
		storeLocalStorage(taskResultsetStateName,taskResultsetState)
		return taskResultsetState
	}
}