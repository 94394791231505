//@flow
import {storeSessionStorage,removeLocalStorage} from './store-util'
const taskResultInfoStateName = 'taskResultInfo'
const localStore = sessionStorage.getItem(taskResultInfoStateName)

export const getStateName=()=> {
    return taskResultInfoStateName
}

export const taskResultInfoInitialState = localStore?JSON.parse(localStore): {
    [taskResultInfoStateName]: {
        error: '',
        loading: false,
        result: null
    }
}

type State = {
    +taskResultInfo: {
        +error: string,
        +loading: boolean,
        +result: any
    }
}

type Action = {
    error: string,
    loading: boolean,
    result: any
}

export const ActionTaskResultInfoFetch = 'taskResultInfoFetch'
export const ActionTaskResultInfoError = 'taskResultInfoError'
export const ActionTaskResultInfoReceived = 'taskResultInfoReceived'

export const taskResultInfoActions = {
    [ActionTaskResultInfoFetch]: (state:State)=> {
        const thisState = {
            [taskResultInfoStateName]: {
                ...state[taskResultInfoStateName],
                loading: true,
                error: ''
            }
        }

        removeLocalStorage(taskResultInfoStateName)
        storeSessionStorage(taskResultInfoStateName,thisState)
		return thisState
    },
    [ActionTaskResultInfoError]: (state:State, action:Action) => {
        const thisState = {
            [taskResultInfoStateName]: {
                ...state[taskResultInfoStateName],
                loading: false,
                error: action.error
            }
        }

        removeLocalStorage(taskResultInfoStateName)
        storeSessionStorage(taskResultInfoStateName,thisState)
		return thisState
    },
    [ActionTaskResultInfoReceived]: (state:State, action:Action) => {
        const thisState = {
            [taskResultInfoStateName]: {
                ...state[taskResultInfoStateName],
                loading: false,
                error: '',
                result: action.result
            }
        }

        removeLocalStorage(taskResultInfoStateName)
        storeSessionStorage(taskResultInfoStateName,thisState)
        return thisState
    }
}