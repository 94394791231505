//@flow
import {storeSessionStorage,removeLocalStorage} from './store-util'
const userStateName = 'user'
const localStore = sessionStorage.getItem(userStateName)

export const userInitialState = localStore?JSON.parse(localStore):{
    user: { 
        loggedIn: false, 
        error: '', 
        jwtToken: '', 
        userInfo: null, 
        googleProfile: null 
    }
}

type State = {
    +user: {
        +loggedIn: boolean,
        +error: string,
        +jwtToken: string,
        +userInfo: any,
        +googleProfile: any
    }
}

type Action = {
    error: string,
    jwtToken: string,
    userInfo: any,
    googleProfile: any
}

export const ActionUserLogin = 'login'
export const ActionUserLogout = 'logout'
export const ActionUserLoginError = 'loginError'

export const userActions = {
    [ActionUserLogin]: (state:State, action:Action) => {
        const userState = { user: { 
            loggedIn: true, error: '', 
            jwtToken: action.jwtToken,
            userInfo: action.userInfo,
            googleProfile: action.googleProfile,  
        }};
        removeLocalStorage(userStateName)
        storeSessionStorage(userStateName,userState)
        return userState
    },
    [ActionUserLogout]: (state:State) => {
        const userState = { user: { loggedIn: false, error: '' } };
        removeLocalStorage(userStateName)
        storeSessionStorage(userStateName,userState)
        return userState
    },
    [ActionUserLoginError]: (state:State,action:Action) => {
        const userState = { user: { loggedIn: false, error: action.error} }
        removeLocalStorage(userStateName)
        storeSessionStorage(userStateName,userState)
        return userState
    }
};