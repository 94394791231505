//@flow
import {storeSessionStorage,removeLocalStorage} from './store-util'
const stateName = 'taskAccept'
const stateFromLocalStore = sessionStorage.getItem(stateName)

export const taskAcceptInitialState = stateFromLocalStore?JSON.parse(stateFromLocalStore):{
    taskAccept: { 
        error: '', 
        loading: false,
        loadingType: '',
        taskAcceptedInfo: null,
        resumeTaskAvailable: false
    }
}

type State = {
    +taskAccept: {
        +error: string,
        +loading: boolean,
        +loadingType: string,
        +taskAcceptedInfo: any
    } 
}

type Action = {
    error: string,
    loading: boolean,
    taskAcceptedInfo: any
}

export const TypeCheckTaskAcceptFetch = 'checkTaskAcceptFetch'
export const TypeTaskAcceptFetch = 'taskAcceptFetch'
export const TypeTaskAcceptError = 'taskAcceptError'
export const TypeResumeTaskAvailable = 'resumeTaskAvailable'
export const TypeResumeTaskDone = 'resumeTaskDone'
export const TypeTaskAcceptSuccess = 'taskAcceptSuccess'
export const TypeTaskAcceptClear = 'taskAcceptClear'

export const taskAcceptActions = {
    checkTaskAcceptFetch: (state:State)=> {
        const taskAcceptState = {
            taskAccept: {
                ...state.taskAccept,
                loading: true,
                loadingType: 'Check',
                error: '',
                resumeTaskAvailable: false
            }
        }

        removeLocalStorage(stateName)
        storeSessionStorage(stateName,taskAcceptState)
        return taskAcceptState
    },
    taskAcceptFetch: (state:State)=> {
        const taskAcceptState = {
            taskAccept: {
                ...state.taskAccept,
                loading: true,
                loadingType: 'Accept',
                error: ''
            }
        }

        removeLocalStorage(stateName)
        storeSessionStorage(stateName,taskAcceptState)
        return taskAcceptState
    },
    taskAcceptError: (state:State, action:Action) => {
        const taskAcceptState = {
            taskAccept: {
                ...state.taskAccept,
                loading: false,
                error: action.error 
            }
		}
			
        removeLocalStorage(stateName)
        storeSessionStorage(stateName,taskAcceptState)
        return taskAcceptState
    },
    resumeTaskAvailable: (state:State, action:Action) => {
        const taskAcceptState = {
            taskAccept: {
                ...state.taskAccept,
                loading: false,
                error: '',
                taskAcceptedInfo: action.taskAcceptedInfo,
                resumeTaskAvailable: true
            }
		}
		
        removeLocalStorage(stateName)
        storeSessionStorage(stateName,taskAcceptState)
        return taskAcceptState
    },
    resumeTaskDone: (state:State) => {
        const taskAcceptState = {
            taskAccept: {
                ...state.taskAccept,
                resumeTaskAvailable: false
            }
		}
		
        removeLocalStorage(stateName)
		storeSessionStorage(stateName,taskAcceptState)
		return taskAcceptState
    },
    taskAcceptSuccess: (state:State, action:Action) => {
        const taskAcceptState = {
            taskAccept: {
                ...state.taskAccept,
                loading: false,
                error: '',
                taskAcceptedInfo: action.taskAcceptedInfo
            }
        }

        removeLocalStorage(stateName)
        storeSessionStorage(stateName,taskAcceptState)
        return taskAcceptState
    },
    taskAcceptClear: (state:State) => {
        const taskAcceptState = {
            taskAccept: {
                ...state.taskAccept,
                loading: false,
                error: '',
                taskAcceptedInfo: null
            }
        }

        removeLocalStorage(stateName)
        storeSessionStorage(stateName,taskAcceptState)
        return taskAcceptState
    }
}