//@flow
import { taskResumeStateName } from './taskResume'
import {storeLocalStorage} from './store-util'

const stateName = 'tasksList'
const localStore = localStorage.getItem(stateName)

export const tasksListInitialState = localStore?JSON.parse(localStore):{
	tasksList: { 
		error: '', 
		taskWorkflows: [],
		taskAcceptedHistories: [], 
		taskType: '', 
		loading: false,
		selectedTask: null,
		taskAcceptRequested: false,
	}
}

type State = {
	+tasksList: {
		+error: string,
		+taskWorkflows: Array<any>,
		+taskAcceptedHistories: Array<any>,
		+taskType: string,
		+loading: boolean,
		+selectedTask: any,
		+taskAcceptRequested: boolean
	},
	+taskResume: {
		+taskCancelled: boolean
	}
}

type Action = {
	error: string,
	taskWorkflows: Array<any>,
	taskAcceptedHistories: Array<any>,
	taskType: string,
	selectedTask: any,
}

export const TypeTasksListFetch = 'tasksListFetch'
export const TypeTasksListError = 'tasksListError'
export const TypeTasksListReceived = 'tasksListReceived'
export const TypeTaskSelected = 'taskSelected'
export const TypeTaskSelectedDone = 'taskSelectedDone'

export const tasksListActions = {
	tasksListFetch: (state:State) => {
		const taskListState = {
			tasksList: {
				...state.tasksList,
				loading: true,
				error: '',
				taskWorkflows: [],
				taskAcceptedHistories: [],
				selectedTask: null,
			}
		}
		
		const taskResumeState = {
			taskResume: {
				...state.taskResume,
				taskCancelled: false
			}
		}

		try {
			//removeLocalStorage(stateName)
			storeLocalStorage(stateName,taskListState)
			//removeLocalStorage(taskResumeStateName)
			storeLocalStorage(taskResumeStateName,taskResumeState)
		} catch (e) {
			console.log('tasksListActions:tasksListFetch: ',e)
		}
		
		const lastState = {
			tasksList: taskListState.tasksList,
			taskResume: taskResumeState.taskResume
		}

		return lastState
	},
	tasksListError: (state:State, action:Action) => {
		const taskListState = { 
			tasksList: { 
				...state.tasksList,
				error: action.error,
				taskType: action.taskType,
				taskWorkflows: [],
				taskAcceptedHistories: [],
				loading: false
			}
		}

		//removeLocalStorage(stateName)
		storeLocalStorage(stateName,taskListState)
		return taskListState
	},
	tasksListReceived: (state:State, action:Action) => {
		const tasksListState = {
			tasksList: {
				...state.tasksList,
				error: '',
				taskType: action.taskType,
				taskWorkflows: action.taskWorkflows,
				taskAcceptedHistories: action.taskAcceptedHistories,
				loading: false
			}
		}

		//removeLocalStorage(stateName)
		storeLocalStorage(stateName,tasksListState)
		return tasksListState
	},
	taskSelected: (state:State,action:Action) => {
		const tasksListState = {
			tasksList: {
					...state.tasksList,
					selectedTask: action.selectedTask,
					taskAcceptRequested: true,
			}
		}

		//removeLocalStorage(stateName)
		storeLocalStorage(stateName,tasksListState)
		return tasksListState
	},
	taskSelectedDone: (state:State) => {
		const tasksListState = {
			tasksList: {
					...state.tasksList,
					taskAcceptRequested: false,
			}
		}

		//removeLocalStorage(stateName)
		storeLocalStorage(stateName,tasksListState)
		return tasksListState
	}
}
