//@flow

export const removeUnusedAttributesInObject = (dataObject:any,unusedAttrs:Array<string>) => {
    if (!dataObject) {
        return dataObject
    }

	unusedAttrs.forEach( field => {
		if (field in dataObject) {
			delete dataObject[field]
		}
	})

	return dataObject
}

export const removeUnusedAttributesInObjectArray = (dataObjects:Array<any>,unusedAttrs:Array<string>) => {
    const newArrays = dataObjects
  
    newArrays.forEach(dataObject => {
        removeUnusedAttributesInObject(dataObject,unusedAttrs)
    })

    return newArrays
}

export const removeLocalStorage = (key:string) => {
    try{
        if (localStorage.getItem(key)){
            localStorage.removeItem(key)
        }
    }catch(e){
        console.error(e);
    }
}

export const storeLocalStorage = (key:string,value:any)=>{
    try{
        if (localStorage.getItem(key)){
            localStorage.removeItem(key) //Deleting existing data in the storage
        }
        localStorage.setItem(key,JSON.stringify(value))
    }catch(e){
        console.error(e);
    }
}

export const storeSessionStorage = (key:string,value:any)=>{
    try{
        if (sessionStorage.getItem(key)){
            sessionStorage.removeItem(key) //Deleting existing data in the storage
        }
        sessionStorage.setItem(key,JSON.stringify(value))
    }catch(e){
        console.log('store-util:storeSessionStorage: ',e)
    }
}