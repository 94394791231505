//@flow
const appVerStateName = 'appVer'
const localStore = localStorage.getItem(appVerStateName)

export const appUserVerInitialState = localStore?JSON.parse(localStore): {
    [appVerStateName]: {
        error: '',
        loading: false,
        appUpdate: false,
        appVersion: '',
        appUpdateTitle: '',
        appUpdateNotes: []
	}
}

type State = {
    +appVer: {
        +error: string,
        +loading: boolean,
        +appUpdate: boolean,
        +appVersion: string,
        +appUpdateTitle: string,
        +appUpdateNotes: Array<any>
    }
}

type Action = {
    error: string,
    appUpdate: boolean,
    appVersion: string,
    appUpdateTitle: string,
    appUpdateNotes: Array<any>
}

export const TypeAppVerError = 'appVerError'
export const TypeAppVerFetch = 'appVerFetch'
export const TypeAppVerReceived = 'appVerReceived'

export const appVerActions = {
    [TypeAppVerError]: (state:State, action:Action) => {
        const appVerState = {
            appVer: {
                ...state.appVer,
                error: action.error
            }
        }
        localStorage.setItem(appVerStateName,JSON.stringify(appVerState))
        return appVerState
    },
    [TypeAppVerFetch]: (state:State) => {
        const appVerState = {
            appVer: {
                ...state.appVer,
                loading: true,
                error: ''
            }
        }
        localStorage.setItem(appVerStateName,JSON.stringify(appVerState))
        return appVerState
    },
    [TypeAppVerReceived]: (state:State,action:Action) => {
        const appVerState = {
            appVer: {
                ...state.appVer,
                loading: false,
                error: '',
                appUpdate: action.appUpdate,
                appVersion: action.appVersion,
                appUpdateTitle: action.appUpdateTitle,
                appUpdateNotes: action.appUpdateNotes,
            }
        }
        localStorage.setItem(appVerStateName,JSON.stringify(appVerState))
        return appVerState
    }
}