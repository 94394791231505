//@flow
import * as crowdApis from '@lesofi/phoenix-js/lib/phoenixgoapi/crowd'
import Package  from '../../package.json'
import { TypeAppVerError,TypeAppVerReceived } from '../store/appVersion'
import compareVersions from 'compare-versions'

const appId = "crowd.gigsdata.com"

export const refreshAppUserVersion=()=>{
    crowdApis.refreshAppUserVersion(appId,Package.version).then(res => {
        res.json().then(data=>{
            if (data && data['error']){
                console.error(data['error'])
            }
        })
    }).catch(err => {
        console.error(err)
    })
}

export const getAppVersion=(dispatch:any)=>{
    const key = 'app-crowd.gigsdata.com-version'
    crowdApis.getParamApi(key,'Map').then(res => {
        res.json().then(data=>{
            if (data && data['error']){
                dispatch({ type: TypeAppVerError, error: data['error']})
            }else if (data['keyMapValue']) {
                const keyData = data['keyMapValue']
                let appUpdate = false 
                const compare = compareVersions(keyData['appVersion'],Package.version)
                
                if (compare === 1) {
                    appUpdate = true
                }
                const appUpdateTitle = keyData['appUpdateTitle']
                const appVersion = keyData['appVersion']
                const appUpdateNotes = keyData['appUpdateNotes']
                dispatch({ type: TypeAppVerReceived, appUpdate, appVersion, appUpdateTitle, appUpdateNotes })
            }
        })
    }).catch(err => {
       dispatch({ type: TypeAppVerError, error: err})
    })
} 