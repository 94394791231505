//@flow
const wsGatewayStateName = 'wsGateway'
const sessionStore = sessionStorage.getItem(wsGatewayStateName)

export const getStateName=()=> {
    return wsGatewayStateName
}

export const wsGatewayInitialState = sessionStore?JSON.parse(sessionStore): {
    [wsGatewayStateName]: {
        error: '',
        waiting: false,
        endpoint: null,
    }
}

type State = {
    +wsGateway: {
        +error: string,
        +waiting: boolean,
        +endpoint: any,
    }
}

type Action = {
    error: string,
    endpoint: any,
}

export const TypeWsGatewayFetch = 'wsGatewayFetch'
export const TypeWsGatewayError = 'wsGatewayError'
export const TypeWsGatewayReceived = 'wsGatewayReceived'

const storeState = (wsGatewayState:any)=>{
    try{
        sessionStorage.setItem(wsGatewayStateName,JSON.stringify(wsGatewayState))
    }catch(e){
        console.log('wsGatewayActions:storeState: ',e)
    }
}

export const wsGatewayActions = {
    [TypeWsGatewayFetch]: (state:State) => {
        const thisTaskState = {
            [wsGatewayStateName]: {
                ...state[wsGatewayStateName],
                waiting: true,
                error: '',
                endpoint: null,
            }
        }
        storeState(thisTaskState)
        return thisTaskState
    },
    [TypeWsGatewayError]: (state:State, action:Action) => {
        const thisTaskState = {
            [wsGatewayStateName]: {
                ...state[wsGatewayStateName],
                waiting: false,
                error: action.error
            }
        }
        storeState(thisTaskState)
        return thisTaskState
    },
    [TypeWsGatewayReceived]: (state:State, action:Action) => {
        const thisTaskState = {
            [wsGatewayStateName]: {
                ...state[wsGatewayStateName],
                waiting: false,
                error: '',
                endpoint: action.endpoint,
            }
        }
        storeState(thisTaskState)
        return thisTaskState
    },
}