//@flow
import {storeSessionStorage} from './store-util'
const genPageStateName = 'genPage'
const genPageSessionStore = sessionStorage.getItem(genPageStateName)

export const getStateName=()=> {
    return genPageStateName
}

export const genPageInitialState = genPageSessionStore?JSON.parse(genPageSessionStore):{
	[genPageStateName]: { 
		root: {}
	}
}

type State = {
    +genPage: {
        root: any
    }
}

type Action = {
    error: string,
    pageDataId: string,
    pageData: any
}

export const TypePageDataFetch = 'pageDataFetch'
export const TypePageDataError = 'pageDataError'
export const TypePageDataReceived = 'pageDataReceived'
export const TypePageDataSearch = 'pageDataSearch'

const getNewState = (state:State,pageDataId:string,pageData:any)=>{
    const thisState = {
        [genPageStateName]: {
            ...state[genPageStateName],
            root: {
                ...state[genPageStateName].root,
                [pageDataId]: pageData
            }
        }
    }

    storeSessionStorage(genPageStateName,thisState)
    return thisState  
}

export const genPageActions = {
    [TypePageDataFetch]: (state:State,action:Action) => {
        const dataRootState = state[genPageStateName].root[action.pageDataId]?state[genPageStateName].root[action.pageDataId]:{}
        let pageData = {...dataRootState}

        pageData = {
            ...pageData,
            error: '',
            loading: true,
        }

        const newState = getNewState(state,action.pageDataId,pageData)  
        return newState      
    },
    [TypePageDataSearch]: (state:State,action:Action) => {
        const dataRootState = state[genPageStateName].root[action.pageDataId]?state[genPageStateName].root[action.pageDataId]:{}
        let pageData = {...dataRootState}

        pageData = {
            ...pageData,
            error: '',
            loading: true,
            searching: true
        }

        const newState = getNewState(state,action.pageDataId,pageData)  
        return newState
    },
    [TypePageDataError]: (state:State,action:Action) => {
        const dataRootState = state[genPageStateName].root[action.pageDataId]?state[genPageStateName].root[action.pageDataId]:{}
        let pageData = {...dataRootState}

        pageData = {
            ...pageData,
            error: action.error,
            loading: false,
            searching: false,
        }

		return getNewState(state,action.pageDataId,pageData)
    },
    [TypePageDataReceived]: (state:State,action:Action) => {
        const dataRootState = state[genPageStateName].root[action.pageDataId]?state[genPageStateName].root[action.pageDataId]:{}
        let pageData = {
            ...dataRootState,
        }
        pageData = {
            ...pageData,
            error: '',
            loading: false,
            searching: false,
            ...action.pageData
        }

        const newState = getNewState(state,action.pageDataId,pageData)  
		return newState 
    }
}